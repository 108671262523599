import type { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import widget from './components/TipsNew/.component.json';
import { SPECS } from './specs';

const defaultPresetId = 'variants-liail3bv';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  if (
    options.firstInstall &&
    flowAPI.experiments.enabled(SPECS.AddWidgetToStage)
  ) {
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: widget.id,
      installationType: 'closed' as WidgetInstallationType,
      layout: {
        height: 100,
        width: 100,
        x: 0,
        y: 100,
      },
      scopedPresets: {
        desktop: {
          layout: defaultPresetId,
          style: defaultPresetId,
        },
        mobile: {
          layout: defaultPresetId,
          style: defaultPresetId,
        },
      },
      layouts: {
        componentLayout: {
          minHeight: {
            type: 'px',
            value: 400,
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'auto',
          },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
        },
      },
    });
  }
};
