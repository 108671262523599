import { ExperimentsBag } from '@wix/yoshi-flow-editor';

export const SPECS = {
  AddWidgetToStage: 'specs.tips.AddWidgetToStage',
};

const openedExperimentsForTests: string[] = [];

const openedExperimentsForSled: string[] = [];

export const getOpenedExperimentsForUnitTests = (): ExperimentsBag => {
  return openedExperimentsForTests.reduce((prv: any, cur: any) => {
    prv[cur] = true;
    return prv;
  }, {});
};

export const getOpenedExperimentsForSledTests = () => {
  return openedExperimentsForSled.reduce((obj, ex) => {
    obj[ex] = true;
    return obj;
  }, {});
};
